/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages

                $('.burger-menu').click(function() {
                    $(this).toggleClass('active');
                });



                window.onscroll = function() {};



                $(document).ready(function() {

                    var body = $('body');

                    $(window).scroll(function (event) {
                        console.log($(window).scrollTop());

                        if ($(this).scrollTop() > 62) {
                            body.addClass("body-fixed-nav");
                        } else {
                            body.removeClass("body-fixed-nav");
                        }
                    });

                    var $magic = $(".reveal"),
                        winW = $(window).width(),
                        winH = $(window).height(),
                        winWHalf = winW / 2,
                        winHHalf = winH / 2,
                        magicWHalf = $magic.width() / 2;
                    $(window).resize(function() {
                        winW = $(window).width();
                        winH = $(window).height();
                        winWHalf = winW / 2;
                        winHHalf = winH / 2;
                    });
                    $(document).on("mousemove", function(e) {
                        var x = e.pageX,
                            y = e.pageY,
                            finalX = x - winWHalf,
                            finalY = y - winHHalf;
                        $magic.css({"left": x - magicWHalf, "top": y - magicWHalf});
                    });

                    setTimeout(function(){
                        $('.img-svg').each(function() {
                            var $img = $(this);
                            var imgURL = $img.attr('src');

                            $.get(imgURL, function(data) {
                                // Get the SVG tag, ignore the rest
                                var $svg = $(data).find('svg');
                                // Replace image with new SVG
                                $img.replaceWith($svg);
                            });
                        });
                    }, 300);

                    $( "#map-locations li" )
                        .on( "mouseenter", function() {
                            var maptarg = $( this ).attr('data-target');
                            $(maptarg).addClass('active');
                            $(maptarg).removeClass('faded');
                            $('.map-marker:not('+maptarg+')').addClass('faded');
                        })
                        .on( "mouseleave", function() {
                            $('.map-marker').removeClass('active');
                            $('.map-marker').removeClass('faded');
                        });


                    setTimeout(function(){
                        $('.img-svg').each(function() {
                            var $img = $(this);
                            var imgURL = $img.attr('src');

                            $.get(imgURL, function(data) {
                                // Get the SVG tag, ignore the rest
                                var $svg = $(data).find('svg');
                                // Replace image with new SVG
                                $img.replaceWith($svg);
                            });
                        });
                    }, 300);

                });

                // Add class each section
                $( window ).on('load', function() {
                    $('.section').each(function() {
                        if($(window).scrollTop() >= $(this).offset().top + $(this).height() || $(window).scrollTop() < $(this).offset().top)
                            $(this).removeClass('active');
                        else
                            $(this).addClass('active');
                    });

                    $('.banner').addClass('active');


                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

                $('.owl-carousel-partners').owlCarousel({
                    loop:true,
                    margin:15,
                    items:5,
                    nav:false,
                    autoplay:true,
                    autoplayTimeout:1000,
                    autoplayHoverPause:true,
                    responsive:{
                        0:{
                            items:2
                        },
                        600:{
                            items:3
                        },
                        1024:{
                            items:4
                        },
                        1000:{
                            items:5
                        }
                    }
                });

                var carousel = $(".owl-carousel-related"); // Activator Class
                carousel.owlCarousel({
                    nav: true,
                    loop:true,
                    pagination: false,
                    dots: false,
                    margin: 35,
                    items:3,
                    autoHeight: false,
                    autoplay:true,
                    autoplayTimeout:3000,
                    autoplayHoverPause:true,
                    // stagePadding: 100,
                    smartSpeed: 500,
                    responsive:{
                        0:{
                            items: 1,
                            stagePadding: 0,
                            margin: 0,
                        },
                        768:{
                            items: 2,
                        },
                        991:{
                            items: 2,
                        },
                        1024:{
                            items: 2,
                        },
                        1025:{
                            items: 3,
                        },
                    },
                });

                $( ".owl-prev").html('<span class="vibrant-arrow vibrant-arrow-left">');
                $( ".owl-next").html('<span class="vibrant-arrow vibrant-arrow-right">');

                function checkClasses(){
                    var total = $('.owl-carousel-related .owl-stage .owl-item.active').length; // nested class from activator class

                    $('.owl-carousel-related .owl-stage .owl-item').removeClass('firstActiveItem lastActiveItem'); // nested class from activator class and remove first and last class if already added.

                    $('.owl-carousel-related .owl-stage .owl-item.active').each(function(index){ // nested class from activator class
                        if (index === 0) {
                            // this is the first one
                            $(this).addClass('firstActiveItem'); // add class in first item
                        }
                        if (index === total - 1 && total>1) {
                            // this is the last one
                            $(this).addClass('lastActiveItem'); // add class in last item
                        }
                    });
                }

                checkClasses();
                carousel.on('translated.owl.carousel', function(event) {
                    checkClasses();
                });
            }
        },
        // Home page
        'home': {
            init: function() {
                $(".preloader svg").addClass("active");


                // JavaScript to be fired on the home page
                var myFullpage = new fullpage('#fullpage', {
                    scrollOverflow: true,
                    navigation: true,
                    navigationPosition: 'left',
                    responsiveWidth: 992,

                    afterLoad: function(origin, destination, direction){
                        var loadedSection = this;

                        //using index
                        if(origin.index == 1){
                            $('.bg-shape-vs svg').addClass('active');
                            $('#video-clock').get(0).play()
                        }
                    },

                    onLeave: function(origin, destination, direction){
                        //it won't scroll if the destination is the 3rd section
                        if(destination.index == 1){
                            $('.clip-path-l2 svg').addClass('active');
                        }
                        if(destination.index == 3){
                            $('.clip-path-l3 svg').addClass('active');
                        }
                    },

                    afterRender: function(){
                        var pluginContainer = this;
                        $('.bg-shape-vs svg').addClass('active');
                        $(".preloader").fadeOut();

                        setTimeout(function(){
                            //do something special
                            var img_gif = $(".animate-splash").attr("src");
                            $('.animate-splash').attr('src', '');
                            $('.animate-splash').attr('src', img_gif);

                        }, 0);

                        setTimeout(function(){

                            $('.watch-container').addClass('is-show');

                        }, 1900);


                    }

                });

                $('.section-scroll').click(function(){
                    fullpage_api.moveSectionDown();
                });


                ///// SECTION 3
                $('.card-slide').mousemove(function(e){
                    // Get dimensions of slide element
                    const r = this.getBoundingClientRect()

                    // Set x and y values prop values based on center of slide element
                    this.style.setProperty('--x', e.clientX - (r.left + Math.floor(r.width / 2)))
                    this.style.setProperty('--y', e.clientY - (r.top + Math.floor(r.height / 2)))
                });

                $('.card-slide').mouseleave(function(e){
                    // Reset x and y prop values when no longer hovering
                    this.style.setProperty('--x', 0)
                    this.style.setProperty('--y', 0)
                });

                // $("document").ready(function() {
                //     $(".watch").trigger('click');
                // });

                $("video").attr("playsinline","");

                // Loader video
                if($('.section-home-1').length) {
                    var containerVideo = document.getElementById('watch');
                    var containerHours = document.getElementById('hours-container');
                    var containerMinutes = document.getElementById('minutes-container');
                    var containerSeconds = document.getElementById('seconds-container');

                    // containerVideo.addEventListener('click', function() {
                    //     loaderVideo.play();
                    // });
                }
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


function animateFrom(elem, direction) {
    direction = direction | 1;

    var x = 0,
        y = direction * 100;
    if(elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = -100;
    } else if(elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 100;
    } else if(elem.classList.contains("gs_reveal_fromRight_plain")) {
        x = 100;
        y = 0;
    } else if(elem.classList.contains("gs_reveal_fromLeft_plain")) {
        x = -100;
        y = 0;
    }

    else if(elem.classList.contains("gs_reveal_fromTop")) {
        x = 0;
        y = 100;
    }

    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.2,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto",
        stagger: 0.1
    });
}

function hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
}

document.addEventListener("DOMContentLoaded", function() {
    gsap.registerPlugin(ScrollTrigger);

    var reveals = gsap.utils.toArray(".gs_reveal");
    gsap.set(reveals, {willChange: "transform, opacity"});
    for(var i = 0; i < reveals.length; i++) {
        (function () {
            var elem = reveals[i];
            hide(elem); // assure that the element is hidden when scrolled into view

            ScrollTrigger.create({
                trigger: elem,
                onEnter: function() { animateFrom(elem) },
                onEnterBack: function() { animateFrom(elem, -1) },
                onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
            });
        })();
    }
});

